
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { KudosWalls } from '../models';
import { kudosService } from '../services';
import KudosWallsList from './KudosWallsList.vue';

@Component({ components: { KudosWallsList } })
export default class KudosWallsPanel extends Vue {
  @Prop({ type: Object, default: () => {} }) readonly config!: Object;

  draftKudosWalls: KudosWalls = [];
  activeKudosWalls: KudosWalls = [];
  closedKudosWalls: KudosWalls = [];

  loading = false;

  get userId(): string {
    return this.$store.getters['user/userId'];
  }

  get filterMode(): string {
    return this.$store.getters['kudos/filterMode'];
  }

  @Watch('filterMode')
  onFilterChange(filterMode: string) {
    if (filterMode) {
      this.load();
    }
  }

  async load() {
    // this.$bus.$emit('loading-indicator', true);
    this.loading = true;
    await Promise.all([
      (this.draftKudosWalls = await kudosService.getKudosWallsCollection(this.userId, 'Draft', this.filterMode)),
      (this.activeKudosWalls = await kudosService.getKudosWallsCollection(this.userId, 'Active', this.filterMode)),
      (this.closedKudosWalls = await kudosService.getKudosWallsCollection(this.userId, 'Closed', this.filterMode)),
    ]);
    this.loading = false;
    // this.$bus.$emit('loading-indicator', false);
  }
  created() {
    this.load();
  }
}
