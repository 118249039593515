
import { Vue, Component, Prop, Model } from 'vue-property-decorator';
const icons = require('./meta.json');

@Component
export default class IconPicker extends Vue {
  @Model('select', { default: 'mdi-image' }) value!: string;
  @Prop({ type: Array, default: () => icons }) icons!: Array<any>;
  @Prop({ type: String, default: () => '' }) size!: string;

  search = '';
  id = '';

  created() {
    this.id = Math.random().toString(36).replace('0.', 'icon-picker');
  }

  selectedIcon(icon: string) {
    this.$emit('select', `mdi-${icon}`);
  }

  updateSearch(e: string) {
    this.search = e;
  }

  get filteredIcons() {
    return this.icons.filter(
      (i) => i.name.includes(this.search) || i.aliases.includes(this.search) || i.tags.includes(this.search)
    );
  }

  get idQuery() {
    return `#${this.id}`;
  }
}
