
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { KudosWall, User } from '../models';
import { kudosService } from '../services';
import KudosWallBuilder from '../components/KudosWallBuilder.vue';

@Component({ components: { KudosWallBuilder } })
export default class KudosWallEdit extends Vue {
  @Prop({ type: String, required: true })
  readonly id!: string;

  kudosWall: KudosWall | null = null;
  isOwner: boolean | null = null;

  get userId(): string {
    return this.$store.getters['user/userId'];
  }
  get user(): User {
    return this.$store.getters['user/user'];
  }

  @Watch('id', { immediate: true })
  onIdChange(id: string) {
    this.$bus.$emit('loading-indicator', true);
    this.$bind('kudosWall', kudosService.getKudosWall(id));
  }

  @Watch('kudosWall')
  onSessionChange(kudosWall: KudosWall) {
    if (kudosWall) {
      document.title = kudosWall.title;
      this.isOwner = kudosWall.owner === this.userId;
      this.$bus.$emit('loading-indicator', false);
    }
  }

  created() {
    // Event listeners
    this.$bus.$off('kudoswall-delete').$on('kudoswall-delete', this.deleteKudosWall);
  }

  mounted() {
    this.$bus.$emit('title-change', 'Kudos', '/kudos');
    this.$bus.$emit('loading-indicator', true);
  }

  async saveKudosWall(kudosWall: KudosWall) {
    try {
      // matrix.createdOn = Date.now();
      // matrix.owner = this.userId;
      await kudosService.updateKudosWall(kudosWall);
      this.$bus.$emit('snackbar-notify', 'Kudos wall successfully saved', 'success');
      this.$analytics.logEvent('kudos-edit', { wall_id: kudosWall.id, user_id: this.userId });
      this.$router.push({ name: 'kudos-list' });
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }

  async deleteKudosWall(kudosWall: KudosWall) {
    try {
      await kudosService.deleteKudosWall(kudosWall);
      this.$bus.$emit('snackbar-notify', 'Kudos wall successfully deleted', 'success');
      this.$analytics.logEvent('kudos-delete', { wall_id: kudosWall.id, user_id: this.userId });
      this.$router.push({ name: 'kudos-list' });
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
}
