
import { Vue, Component, Prop } from 'vue-property-decorator';
import { VEmojiPicker } from 'v-emoji-picker';

@Component({ components: { VEmojiPicker } })
export default class EmojiPicker extends Vue {
  @Prop({ type: Boolean, default: false }) dialog!: boolean;

  selectEmoji(emoji) {
    this.$emit('select', emoji.data);
  }
}
