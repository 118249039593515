
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { func } from '@/firebase';
import { Kudo, KudosWall, Comment } from '../models';
import { kudosService } from '../services';
import KudoCard from '../components/KudoCard.vue';
import KudoEditDialog from '../components/dialogs/KudoEditDialog.vue';

@Component({ components: { KudoCard, KudoEditDialog } })
export default class KudoCardView extends Vue {
  @Prop({ type: String, required: true })
  readonly id!: string;

  @Prop({ type: String, required: true })
  readonly kudoId!: string;

  kudosService = kudosService;
  kudosWall: KudosWall | null = null;
  kudoEditDialog: boolean = false;

  get userId(): string {
    return this.$store.getters['user/userId'];
  }

  get kudo() {
    return this.kudosWall?.kudos[this.kudoId] || null;
  }

  @Watch('id', { immediate: true })
  async onIdChange(id: string) {
    this.$bus.$emit('loading-indicator', true);
    await this.$bind('kudosWall', kudosService.getKudosWall(id));
    this.$bus.$emit('loading-indicator', false);
  }

  created() {
    this.$bus.$off('kudo-edit').$on('kudo-edit', this.onKudoEdit);
    this.$bus.$off('kudo-delete').$on('kudo-delete', this.onKudoDelete);
    this.$bus.$off('kudo-comment-create').$on('kudo-comment-create', this.onKudoCommentCreate);
    this.$bus.$off('kudo-comment-delete').$on('kudo-comment-delete', this.onKudoCommentDelete);
    this.$bus.$off('kudo-like-toggle').$on('kudo-like-toggle', this.onKudoLikeToggle);
    this.$bus.$off('kudo-love-toggle').$on('kudo-love-toggle', this.onKudoLoveToggle);
  }

  mounted() {
    this.$bus.$emit('title-change', 'Kudos', '/kudos');
    this.$bus.$emit('loading-indicator', true);
  }

  async saveKudo(kudo: Kudo) {
    this.kudoEditDialog = false;
    kudo.date = Date.now();
    if (kudo.anonymous) delete kudo.from;
    try {
      await kudosService.saveKudo(this.id, kudo);
      if (kudo.email) this.sendKudoMail(kudo);
      this.$bus.$emit('snackbar-notify', 'Kudo successfully sent', 'success');
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
  sendKudoMail(kudo: Kudo) {
    if (!kudo.from?.email) {
      this.$bus.$emit('snackbar-notify', 'Sender email is missing', 'error');
      return;
    }
    if (!kudo.to?.email) {
      this.$bus.$emit('snackbar-notify', 'Recipient email is missing', 'error');
      return;
    }
    try {
      kudosService.sendMail(kudo, this.kudosWall!.id);
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
      console.error(error);
    }
  }

  onKudoEdit(kudo: Kudo) {
    this.kudoEditDialog = true;
  }
  async onKudoDelete(kudo: Kudo) {
    try {
      await kudosService.deleteKudo(this.id, kudo);
      this.$bus.$emit('snackbar-notify', 'Kudo successfully deleted', 'success');
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
  onKudoCommentCreate(kudo: Kudo, comment: Comment) {
    if (!this.kudosWall) return;
    kudosService.createKudoComment(this.kudosWall.id, kudo.id, comment);
  }
  onKudoCommentDelete(kudo: Kudo, comment: Comment) {
    if (!this.kudosWall) return;
    kudosService.deleteKudoComment(this.kudosWall.id, kudo.id, comment);
  }
  onKudoLikeToggle(kudo: Kudo, likes: number) {
    if (!this.kudosWall) return;
    kudosService.likeKudo(this.kudosWall.id, kudo.id, this.userId, likes);
  }
  onKudoLoveToggle(kudo: Kudo, loves: number) {
    if (!this.kudosWall) return;
    kudosService.loveKudo(this.kudosWall.id, kudo.id, this.userId, loves);
  }
  navigateBack() {
    window.history.length > 1 ? this.$router.go(-1) : this.$router.push(`/kudos/${this.kudosWall!.id}`);
  }
}
