
import { Vue, Component, Model, ModelSync, Prop, PropSync } from 'vue-property-decorator';
import { KudosWall, Kudo, User, kudosWallStatuses } from '../../models';
import { formatDate } from '@/utils';
import { kudosService } from '../../services';
import Person from '@/components/Person.vue';
import EmojiPicker from '@/components/EmojiPicker.vue';
import KudoCard from '../KudoCard.vue';

@Component({ components: { Person, EmojiPicker, KudoCard } })
export default class KudoViewDialog extends Vue {
  @ModelSync('value', 'show', { default: false }) show!: Boolean;

  @Prop({ required: true }) readonly kudosWall!: KudosWall;
  // @PropSync('kudoValue') kudo!: Kudo;
  @Prop({ required: true }) readonly kudo!: Kudo;

  kudosService = kudosService;
  formatDate = formatDate;
  showComments = false;

  get userId(): string {
    return this.$store.getters['user/userId'];
  }
  get user(): User {
    return this.$store.getters['user/user'];
  }

  get isReadOnly() {
    return this.kudosWall?.status.name === kudosWallStatuses.CLOSED.name;
  }
  get isOwner() {
    return this.kudo.from?.id === this.userId || this.kudosWall.owner === this.userId;
  }

  get comments() {
    return this.kudo.comments || [];
  }

  get likes() {
    return this.kudo.likes ? Object.values(this.kudo.likes).reduce((acc, val) => acc + val, 0) : 0;
  }

  get loves() {
    return this.kudo.loves ? Object.values(this.kudo.loves).reduce((acc, val) => acc + val, 0) : 0;
  }

  // commentKudo(kudo) {
  //   if (this.isReadOnly) return;
  //   // if (!kudo.comments) kudo.comments = [];
  //   const newComment: Comment = { id: getId(), text: this.comment.trim(), date: Date.now(), from: this.user };
  //   // kudo.comments.push(newComment);
  //   kudosService.commentKudo(this.kudosWall.id, kudo.id, newComment);
  //   this.comment = '';
  // }

  likeKudo(kudo) {
    if (this.isReadOnly) return;
    if (!kudo.likes) kudo.likes = {};
    const newLike = kudo.likes[this.userId] ? 0 : 1;
    kudosService.likeKudo(this.kudosWall.id, kudo.id, this.userId, newLike);
  }

  loveKudo(kudo) {
    if (this.isReadOnly) return;
    if (!kudo.loves) kudo.loves = {};
    const newLove = kudo.loves[this.userId] ? 0 : 1;
    kudosService.loveKudo(this.kudosWall.id, kudo.id, this.userId, newLove);
  }

  toggleComments(kudo) {
    this.showComments = !this.showComments;
  }
}
