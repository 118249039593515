
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Comment, Kudo, KudosWall, kudosWallStatuses, User } from '../models';
import { formatDate, getId } from '@/utils';
import Person from '@/components/Person.vue';

@Component({ components: { Person } })
export default class KudoCard extends Vue {
  @Prop({ type: Object as () => Kudo, required: true }) readonly value!: Kudo;
  @Prop({ type: Object as () => KudosWall, required: true }) readonly kudosWall!: KudosWall;
  @Prop({ type: String, default: 'auto' }) readonly width!: string;

  formatDate = formatDate;
  showComments = false;
  comment = '';

  get userId(): string {
    return this.$store.getters['user/userId'];
  }
  get user(): User {
    return this.$store.getters['user/user'];
  }

  get isReadOnly() {
    return this.kudosWall?.status.name === kudosWallStatuses.CLOSED.name;
  }
  get isOwner() {
    return this.value.from?.id === this.userId || this.kudosWall.owner === this.userId;
  }

  get comments() {
    return this.value.comments || [];
  }

  get likes() {
    return this.value.likes ? Object.values(this.value.likes).reduce((acc, val) => acc + val, 0) : 0;
  }

  get loves() {
    return this.value.loves ? Object.values(this.value.loves).reduce((acc, val) => acc + val, 0) : 0;
  }

  editKudo(kudo: Kudo) {
    if (this.isReadOnly) return;
    this.$bus.$emit('kudo-edit', kudo);
  }

  deleteKudo(kudo: Kudo) {
    if (this.isReadOnly) return;
    this.$bus.$emit('kudo-delete', kudo);
  }

  createKudoComment(kudo: Kudo) {
    if (this.isReadOnly) return;
    const newComment: Comment = { id: getId(), text: this.comment.trim(), date: Date.now(), from: this.user };
    this.$bus.$emit('kudo-comment-create', kudo, newComment);
    this.comment = '';
  }
  deleteKudoComment(kudo: Kudo, comment: Comment) {
    if (this.isReadOnly) return;
    this.$bus.$emit('kudo-comment-delete', kudo, comment);
  }

  likeKudo(kudo: Kudo) {
    if (this.isReadOnly) return;
    if (!kudo.likes) kudo.likes = {};
    const newLike = kudo.likes[this.userId] ? 0 : 1;
    this.$bus.$emit('kudo-like-toggle', kudo, newLike);
  }

  loveKudo(kudo: Kudo) {
    if (this.isReadOnly) return;
    if (!kudo.loves) kudo.loves = {};
    const newLove = kudo.loves[this.userId] ? 0 : 1;
    this.$bus.$emit('kudo-love-toggle', kudo, newLove);
  }

  toggleComments() {
    this.showComments = !this.showComments;
  }

  handleGif(gif) {
    console.log('gif', gif);
  }
}
