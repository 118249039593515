
import { Vue, Component, PropSync, Watch } from 'vue-property-decorator';
import { KudoCategories, kudoCategories, KudoCategory, KudosWall, kudosWallStatuses, kudosWallTypes } from '../models';
import { kudosService } from '../services';
import ColorPicker from '@/components/ColorPicker.vue';
import IconPicker from '@/components/IconPicker.vue';

@Component({ components: { ColorPicker, IconPicker } })
export default class KudosWallBuilder extends Vue {
  @PropSync('value', { type: Object as () => KudosWall, required: true }) kudosWall!: KudosWall;

  kudosService = kudosService;
  kudosWallTypes = kudosWallTypes;
  kudoCategories = kudoCategories;

  config = {};
  valid = false;
  categories: KudoCategories = [];

  get kudosWallExists(): boolean {
    return this.kudosWall.createdOn !== undefined;
  }

  @Watch('session.title')
  onTitleChange(title) {
    document.title = title;
  }

  saveKudosWall() {
    this.kudosWall.kudoCategories = this.categories;
    this.$emit('save', this.kudosWall);
  }

  deleteKudosWall() {
    // this.$emit('delete', this.kudosWall);
    this.$bus.$emit('kudoswall-delete', this.kudosWall);
  }

  setKudosWallStatus(status: string) {
    if (status === 'Active') {
      this.kudosWall.status = kudosWallStatuses.ACTIVE;
    } else if (status === 'Closed') {
      this.kudosWall.status = kudosWallStatuses.CLOSED;
    }
    this.saveKudosWall();
  }

  addNewCategory() {
    const category = {
      title: '',
      // description: undefined,
      // icon: undefined,
      color: 'primary',
    } as KudoCategory;
    this.addCategory(category);
  }
  addCategory(category: KudoCategory) {
    this.categories.push(category);
  }
  deleteCategory(category: KudoCategory, index: number) {
    this.categories.splice(index, 1);
  }

  created() {
    this.categories = this.kudosWall.kudoCategories || kudoCategories;
  }
}
