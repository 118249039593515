
import { Vue, Component } from 'vue-property-decorator';
import { KudosWall, User, FilterModes } from '../models';
import { kudosService } from '../services';
import KudosWallsPanel from '../components/KudosWallsPanel.vue';
import KudosWallImportDialog from '../components/dialogs/KudosWallImportDialog.vue';

@Component({ components: { KudosWallsPanel, KudosWallImportDialog } })
export default class KudosDashboard extends Vue {
  config = {};
  showImportDialog = false;
  // filterItems = ['Facilitator view', 'Participant view'];
  FilterModes = FilterModes;

  get userId(): string {
    return this.$store.getters['user/userId'];
  }
  get user(): User {
    return this.$store.getters['user/user'];
  }

  get viewModeIcon(): string {
    return this.$store.getters['user/viewModeIcon'];
  }

  get filterMode(): string {
    return this.$store.getters['kudos/filterMode'];
  }

  setFilter(filter: string) {
    this.$store.dispatch('kudos/setFilterMode', filter);
  }

  created() {
    // Event listeners
    this.$bus.$off('kudoswall-clone').$on('kudoswall-clone', this.onKudosWallClone);
    this.$bus.$off('kudoswall-export').$on('kudoswall-export', this.onKudosWallExport);
    this.$bus.$off('kudoswall-delete').$on('kudoswall-delete', this.onKudosWallDelete);
  }

  mounted() {
    this.$bus.$emit('title-change', 'Kudos', '/kudos');
    document.title = 'AgileMate Kudos';
    //this.$analytics.setCurrentScreen(document.title);
    this.$analytics.logEvent('kudos-dashboard');
    this.$bind('config', kudosService.configRef);
  }

  // Event handlers
  async onKudosWallClone(wall: KudosWall) {
    try {
      await kudosService.cloneKudosWall(wall, this.user);
      this.$bus.$emit('snackbar-notify', 'Kudos wall successfully cloned', 'success');
      this.$analytics.logEvent('kudos-clone', { wall_id: wall.id, user_id: this.userId });
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
  async onKudosWallExport(wall: KudosWall) {
    try {
      kudosService.exportKudosWall(wall);
      this.$bus.$emit('snackbar-notify', 'Kudos wall successfully exported', 'success');
      this.$analytics.logEvent('kudos-export', { wall_id: wall.id, user_id: this.userId });
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
  async onKudosWallDelete(wall: KudosWall) {
    try {
      await kudosService.deleteKudosWall(wall);
      this.$bus.$emit('snackbar-notify', 'Kudos wall successfully deleted', 'success');
      this.$analytics.logEvent('kudos-delete', { wall_id: wall.id, user_id: this.userId });
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
}
