
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { func } from '@/firebase';
import { formatDate } from '@/utils';
import { Kudo, kudosWallStatuses, KudosWall, kudoCategories, User, TeamMember, Comment } from '../models';
import { kudosService } from '../services';
import Participants from '@/components/Participants.vue';
import KudoCard from '../components/KudoCard.vue';
import KudoEditDialog from '../components/dialogs/KudoEditDialog.vue';
import KudoViewDialog from '../components/dialogs/KudoViewDialog.vue';

@Component({ components: { KudoCard, KudoEditDialog, KudoViewDialog, Participants } })
export default class KudosWallView extends Vue {
  @Prop({ type: String, required: true })
  readonly id!: string;

  kudosService = kudosService;
  kudosWallStatuses = kudosWallStatuses;
  formatDate = formatDate;
  kudosWall: KudosWall | null = null;
  kudoEditDialog: boolean = false;
  kudoViewDialog: boolean = false;
  fab: boolean = false;
  newKudo: Kudo | null = null;
  kudoCategories = kudoCategories;
  selectedMember: TeamMember | null = null;

  get userId(): string {
    return this.$store.getters['user/userId'];
  }
  get user(): User {
    return this.$store.getters['user/user'];
  }

  get isReadOnly(): boolean {
    return this.kudosWall?.status.name === kudosWallStatuses.CLOSED.name;
  }
  get isOwner(): boolean {
    return this.kudosWall?.owner === this.userId;
  }
  get isAdmin(): boolean {
    return this.kudosWall?.owner === this.userId;
  }

  get people(): TeamMember[] {
    const people = this.kudosWall && this.kudosWall.people ? Object.values(this.kudosWall.people) : [];
    return people.sort((a, b) => (a.displayName || a.username)!.localeCompare(b.displayName || b.username!));
  }

  get orderedKudos() {
    return this.kudosWall ? Object.values(this.kudosWall.kudos).sort((a, b) => (b.date || 0) - (a.date || 0)) : [];
  }

  get textColorClass() {
    return this.$vuetify.theme.dark ? 'white--text' : this.kudosWall?.background ? 'white--text' : 'default--text';
  }

  @Watch('id', { immediate: true })
  async onIdChange(id: string) {
    this.$bus.$emit('loading-indicator', true);
    this.$bind('kudosWall', kudosService.getKudosWall(id));
    await this.connect();
  }

  @Watch('kudosWall')
  onSessionChange(kudosWall: KudosWall) {
    if (kudosWall) {
      document.title = kudosWall.title;
      this.$bus.$emit('loading-indicator', false);
    }
  }

  created() {
    // Event listeners
    this.$bus.$off('kudoswall-delete').$on('kudoswall-delete', this.onKudosWallDelete);
    this.$bus.$off('kudo-edit').$on('kudo-edit', this.onKudoEdit);
    this.$bus.$off('kudo-delete').$on('kudo-delete', this.onKudoDelete);
    this.$bus.$off('kudo-comment-create').$on('kudo-comment-create', this.onKudoCommentCreate);
    this.$bus.$off('kudo-comment-delete').$on('kudo-comment-delete', this.onKudoCommentDelete);
    this.$bus.$off('kudo-like-toggle').$on('kudo-like-toggle', this.onKudoLikeToggle);
    this.$bus.$off('kudo-love-toggle').$on('kudo-love-toggle', this.onKudoLoveToggle);
  }

  mounted() {
    this.$bus.$emit('title-change', 'Kudos', '/kudos');
    this.$bus.$emit('loading-indicator', true);
  }

  async connect() {
    if (this.isReadOnly) return;
    let person: TeamMember;
    if (this.kudosWall?.people[this.userId]) {
      person = this.kudosWall?.people[this.userId];
      person = { ...person, ...this.user };
    } else {
      person = this.user;
    }
    person.lastJoinedOn = Date.now(); // Update last joined date
    person.connected = true;
    await kudosService.savePerson(this.id, person);
    // this.$bus.$emit('snackbar-notify', 'Kudos wall user connected', 'success');
  }

  selectMember(member: TeamMember) {
    this.selectedMember = member;
  }

  viewKudo(kudo: Kudo) {
    // this.newKudo = kudo;
    // this.kudoViewDialog = true;
    this.$router.push(`/kudos/${this.id}/kudo/${kudo.id}`);
  }
  createKudo() {
    this.newKudo = kudosService.getNewKudo(this.user);
    console.log('New Kudo', this.newKudo);
    this.kudoEditDialog = true;
  }
  async saveKudo(kudo: Kudo) {
    this.kudoEditDialog = false;
    kudo.date = Date.now();
    // if (kudo.anonymous) delete kudo.from;
    try {
      await kudosService.saveKudo(this.id, kudo);
      if (kudo.email) this.sendKudoMail(kudo);
      this.$bus.$emit('snackbar-notify', 'Kudo successfully sent', 'success');
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
  sendKudoMail(kudo: Kudo) {
    if (!kudo.from?.email) {
      this.$bus.$emit('snackbar-notify', 'Sender email is missing', 'error');
      return;
    }
    if (!kudo.to?.email) {
      this.$bus.$emit('snackbar-notify', 'Recipient email is missing', 'error');
      return;
    }
    try {
      kudosService.sendMail(kudo, this.kudosWall!.id);
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
      console.error(error);
    }
  }

  async onKudosWallDelete(kudosWall: KudosWall) {
    try {
      await kudosService.deleteKudosWall(kudosWall);
      this.$bus.$emit('snackbar-notify', 'Kudos wall successfully deleted', 'success');
      this.$analytics.logEvent('kudos-delete', { wall_id: kudosWall.id, user_id: this.userId });
      this.$router.push({ name: 'kudos-list' });
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
  onKudoEdit(kudo: Kudo) {
    this.newKudo = kudo;
    this.kudoEditDialog = true;
  }
  async onKudoDelete(kudo: Kudo) {
    try {
      await kudosService.deleteKudo(this.id, kudo);
      this.$bus.$emit('snackbar-notify', 'Kudo successfully deleted', 'success');
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
  onKudoCommentCreate(kudo: Kudo, comment: Comment) {
    if (!this.kudosWall) return;
    kudosService.createKudoComment(this.kudosWall.id, kudo.id, comment);
  }
  onKudoCommentDelete(kudo: Kudo, comment: Comment) {
    if (!this.kudosWall) return;
    kudosService.deleteKudoComment(this.kudosWall.id, kudo.id, comment);
  }
  onKudoLikeToggle(kudo: Kudo, likes: number) {
    if (!this.kudosWall) return;
    kudosService.likeKudo(this.kudosWall.id, kudo.id, this.userId, likes);
  }
  onKudoLoveToggle(kudo: Kudo, loves: number) {
    if (!this.kudosWall) return;
    kudosService.loveKudo(this.kudosWall.id, kudo.id, this.userId, loves);
  }
}
