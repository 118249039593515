
import { Vue, Component, ModelSync } from 'vue-property-decorator';
import { KudosWall } from '../../models';
import { kudosService } from '../../services';

@Component
export default class KudosWallImportDialog extends Vue {
  @ModelSync('value', 'show', { default: false }) show!: Boolean;

  importing = false;
  importFiles: File[] = [];

  get userId(): string {
    return this.$store.getters['user/userId'];
  }

  async onKudosWallImport() {
    try {
      for (let file of this.importFiles) {
        this.importing = true;
        const kudosWall: KudosWall = await kudosService.importKudosWall(file, this.userId);
        await kudosService.createKudosWall(kudosWall);
        this.$bus.$emit('snackbar-notify', 'Kudos wall successfully imported', 'success');
        this.show = false;
        this.importFiles = [];
        this.$analytics.logEvent('kudos-import', { wall_id: kudosWall.id, user_id: this.userId });
      }
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
    this.importing = false;
  }
}
