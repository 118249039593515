import { db, func, firestore } from '@/firebase';
import { deepCopy, downloadJson, getId } from '@/utils';

import {
  Kudo,
  KudosWall,
  kudosWallStatuses,
  kudosWallTypes,
  TeamMember,
  User,
  Comment,
  kudoCategories,
} from './models';

export const kudosService = {
  adminRef: db.collection('admin'),
  configRef: db.collection('admin').doc('kudos'),
  kudosRef: db.collection('kudos'),
  mailRef: db.collection('mail'),

  getNewKudosWall(userId: string): KudosWall {
    return {
      id: getId(),
      title: 'Kudos Wall',
      description: '',
      background: '',
      type: kudosWallTypes.DEFAULT,
      status: kudosWallStatuses.DRAFT,
      kudoCategories: kudoCategories,
      kudos: {},
      people: {},
      config: {},
      owner: userId,
      // createdOn: Date.now(),
      permissions: {
        owner: userId,
        admins: [userId],
        members: [],
        viewers: [],
      },
    };
  },
  getNewKudo(user: User): Kudo {
    return {
      id: getId(),
      title: '',
      description: '',
      type: kudoCategories[0],
      from: user,
      // to: null,
      anonymous: false,
      email: false,
      // date: Date.now(),
    };
  },

  getKudosWall(id: string) {
    return this.kudosRef.doc(id);
  },
  getKudosWalls(userId: string) {
    return this.kudosRef.where('owner', '==', userId);
  },
  getKudosWallsByStatus(userId: string, status: string, filter?: string) {
    return this.kudosRef.where('owner', '==', userId).where('status.name', '==', status);
    // return this.getKudosWalls(userId, filter).where('status.name', '==', status);
    // return this.kudosRef.where('owner', '==', userId).where('status.name', '==', status);
    // .orderBy('createdOn', 'desc')
    // const result = await this.kudosRef
    // .where('people', 'array-contains', userId)
    // .where('status.name', '==', status)
    // .get();
    // console.log('getKudosWallsByStatus', userId, status, filter, result.docs);
    // return () => result.docs;
  },
  async getKudosWallsCollection(userId: string, status: string, filter?: string) {
    const query = this.kudosRef.where('status.name', '==', status);
    const snapshot = await query.get();
    let data = snapshot.docs.map((doc) => doc.data() as KudosWall);
    if (filter === 'owner') {
      data = data.filter((doc) => doc.owner == userId);
    } else if (filter === 'member') {
      data = data.filter((doc) => doc.people[userId]);
    } else if (filter === 'all') {
      data = data.filter((doc) => doc.owner == userId || doc.people[userId]);
    }
    return data.sort((a, b) => (b.createdOn ?? 0) - (a.createdOn ?? 0));
  },

  createKudosWall(kudosWall: KudosWall) {
    kudosWall.createdOn = Date.now();
    // this.skillsRef.doc(matrix.id).update(matrix);
    this.kudosRef.doc(kudosWall.id).set(kudosWall, { merge: true });
  },
  updateKudosWall(kudosWall: KudosWall | firestore.UpdateData) {
    kudosWall.updatedOn = Date.now();
    this.updateKudosWallData(kudosWall.id, kudosWall);
  },
  updateKudosWallData(kudosWallId: string, kudosWallData: firestore.UpdateData) {
    this.kudosRef.doc(kudosWallId).update(kudosWallData);
  },
  deleteKudosWall(kudosWall: KudosWall) {
    this.kudosRef.doc(kudosWall.id).delete();
  },
  saveKudosWall(kudosWall: KudosWall) {
    this.createKudosWall(kudosWall);
  },
  setKudosWallStatus(kudosWall: KudosWall, status: string) {
    let kudosWallStatus = kudosWallStatuses.DRAFT;
    if (status === kudosWallStatuses.ACTIVE.name) {
      kudosWallStatus = kudosWallStatuses.ACTIVE;
    } else if (status === kudosWallStatuses.CLOSED.name) {
      kudosWallStatus = kudosWallStatuses.CLOSED;
    }
    this.kudosRef.doc(kudosWall.id).update({ status: kudosWallStatus });
  },
  exportKudosWall(kudosWall: KudosWall) {
    downloadJson(kudosWall, 'kudos-' + kudosWall.id);
  },
  importKudosWall(file: File, userId: string): Promise<KudosWall> {
    const reader = new FileReader();
    let content: string | ArrayBuffer | null = null;
    let kudosWall: KudosWall | null = null;
    return new Promise((resolve, reject) => {
      reader.readAsText(file, 'UTF-8');
      reader.onload = (event) => {
        if (event && event.target && event.target.result) {
          content = event.target.result;
          try {
            kudosWall = JSON.parse(content.toString());
            if (kudosWall) {
              kudosWall.id = getId(); // Remap id to avoid conflicts!
              kudosWall.title = kudosWall.title + ' (Import)';
              kudosWall.owner = userId;
              kudosWall.createdOn = Date.now();
              kudosWall.updatedOn = null;
              resolve(kudosWall);
            } else {
              reject('Kudos wall content missing');
            }
          } catch (error) {
            reject('Kudos wall content malformed');
          }
        }
      };
      reader.onerror = () => {
        reject('Kudos wall import failed');
      };
    });
  },
  async cloneKudosWall(kudosWall: KudosWall, user: User) {
    const newKudosWall: KudosWall = deepCopy(kudosWall);
    newKudosWall.id = getId();
    newKudosWall.status = kudosWallStatuses.DRAFT;
    newKudosWall.title = kudosWall.title + ' (Copy)';
    newKudosWall.people = {};
    newKudosWall.config.filterPersonId = null;
    newKudosWall.owner = user.id;
    newKudosWall.createdOn = Date.now();
    newKudosWall.updatedOn = null;
    await this.createKudosWall(newKudosWall);
    return newKudosWall;
  },

  savePerson(kudosWallId: string, person: TeamMember) {
    this.kudosRef.doc(kudosWallId).update({ [`people.${person.id}`]: person });
  },
  savePersonData(kudosWallId: string, personId: string, personData: Partial<TeamMember>) {
    this.kudosRef.doc(kudosWallId).update({ [`people.${personId}`]: personData });
  },

  saveKudo(kudosWallId: string, kudo: Kudo) {
    this.kudosRef.doc(kudosWallId).update({ [`kudos.${kudo.id}`]: kudo });
  },
  deleteKudo(kudosWallId: string, kudo: Kudo) {
    this.kudosRef.doc(kudosWallId).update({ [`kudos.${kudo.id}`]: firestore.FieldValue.delete() });
  },
  createKudoComment(kudosWallId: string, kudoId: string, comment: Comment) {
    this.kudosRef.doc(kudosWallId).update({ [`kudos.${kudoId}.comments`]: firestore.FieldValue.arrayUnion(comment) });
  },
  deleteKudoComment(kudosWallId: string, kudoId: string, comment: Comment) {
    this.kudosRef.doc(kudosWallId).update({ [`kudos.${kudoId}.comments`]: firestore.FieldValue.arrayRemove(comment) });
  },
  likeKudo(kudosWallId: string, kudoId: string, userId: string, likes: number) {
    this.kudosRef.doc(kudosWallId).update({ [`kudos.${kudoId}.likes.${userId}`]: likes });
  },
  loveKudo(kudosWallId: string, kudoId: string, userId: string, loves: number) {
    this.kudosRef.doc(kudosWallId).update({ [`kudos.${kudoId}.loves.${userId}`]: loves });
  },

  async sendMail(kudo: Kudo, kudosWallId: string) {
    const appUrl = process.env.VUE_APP_URL || 'http://localhost:8080';
    try {
      // await this.mailRef.add({ to, message: { subject, html: body } });
      if (kudo.to)
        await this.mailRef.add({
          toUids: [kudo.to.id],
          template: {
            name: 'kudo',
            data: {
              username: kudo.from?.username || kudo.from?.displayName || 'Someone',
              type: kudo.title || kudo.type.title,
              kudo: kudo.description || '',
              link: `${appUrl}/kudos/${kudosWallId}/kudo/${kudo.id}`,
            },
          },
        });
    } catch (error) {
      console.error('[sendMail] error', error);
    }
  },

  async sendMailFunc(from: string = 'agilemate.dev@gmail.com', to: string, subject: string, body: string) {
    const sendMail = func.httpsCallable('sendMail');
    const result = await sendMail({ from, to, subject, body });
    return result;
  },
  async genaiChat(prompt: string) {
    const genaiChat = func.httpsCallable('genaiChat');
    const result = await genaiChat({ prompt });
    return result;
  },
};
