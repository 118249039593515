
import { Vue, Component, Prop } from 'vue-property-decorator';
import { TeamMember } from '../models';
import { formatDate } from '@/utils';

@Component
export default class Person extends Vue {
  @Prop({ type: Object as () => TeamMember, required: true }) readonly value!: TeamMember;

  formatDate = formatDate;
}
