
import { Vue, Component, Prop } from 'vue-property-decorator';
import { KudosWall } from '../models';

@Component
export default class KudosWallActions extends Vue {
  @Prop({ type: Object as () => KudosWall, required: true }) readonly value!: KudosWall;

  get userId(): string {
    return this.$store.getters['user/userId'];
  }

  get isOwner(): boolean {
    return this.value.owner === this.userId;
  }
}
