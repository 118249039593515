
import { Vue, Component } from 'vue-property-decorator';
import { KudosWall } from '../models';
import { kudosService } from '../services';
import KudosWallBuilder from '../components/KudosWallBuilder.vue';

@Component({ components: { KudosWallBuilder } })
export default class KudosWallCreate extends Vue {
  kudosWall: KudosWall = kudosService.getNewKudosWall(this.userId);

  get userId(): string {
    return this.$store.getters['user/userId'];
  }

  mounted() {
    this.$bus.$emit('title-change', 'Kudos', '/kudos');
    document.title = 'AgileMate New Kudos Wall';
    //this.$bus.$emit('loading-indicator', true);
  }

  async saveKudosWall(kudosWall: KudosWall) {
    try {
      await kudosService.createKudosWall(kudosWall);
      this.$bus.$emit('snackbar-notify', 'Kudos wall successfully saved', 'success');
      this.$analytics.logEvent('kudos-create', { wall_id: kudosWall.id, user_id: this.userId });
      this.$router.push({ name: 'kudos-list' });
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
}
