import type { User, Participant as TeamMember } from '@/models';

export type TeamMembers = { [userId: string]: TeamMember };

export { User, TeamMember };

export const kudosWallStatuses = {
  DRAFT: { name: 'Draft', color: 'warning' },
  ACTIVE: { name: 'Active', color: 'success' },
  CLOSED: { name: 'Closed', color: 'error' },
};
export type KudosWallStatus = typeof kudosWallStatuses.DRAFT;

export const kudosWallTypes = {
  DEFAULT: { name: 'Kudos wall' },
  TEAM: { name: 'Team wall' },
  PRODUCT: { name: 'Product wall' },
  DEPARTMENT: { name: 'Department wall' },
  ORGANIZATION: { name: 'Organization wall' },
};
export type KudosWallType = typeof kudosWallTypes.DEFAULT;

export interface KudoCategory {
  title: string;
  description?: string;
  icon?: string;
  color?: string;
  points?: number | null;
}
export type KudoCategories = KudoCategory[];
export const kudoCategories: KudoCategories = [
  { title: 'Thank You', icon: 'mdi-account-tie-voice', color: 'indigo' },
  { title: 'Achiever', icon: 'mdi-account-check', color: 'cyan' },
  { title: 'Awesome', icon: 'mdi-account-tie', color: 'blue' },
  { title: 'Coach', icon: 'mdi-account-supervisor', color: 'green' },
  { title: 'Creator', icon: 'mdi-account-question', color: 'amber' },
  { title: 'Kind Heart', icon: 'mdi-account-heart', color: 'purple' },
  { title: 'Leader', icon: 'mdi-account-star', color: 'brown' },
  { title: 'Problem Solver', icon: 'mdi-account-search', color: 'teal' },
  { title: 'Team Player', icon: 'mdi-account-group', color: 'blue-grey' },
];

export type Comment = {
  id: string;
  text: string;
  date: number;
  from: TeamMember;
};
export type Comments = Comment[];
export type Likes = { [userId: string]: number };
export type Loves = { [userId: string]: number };

export const FilterModes = {
  ALL: { id: 'all', title: 'Show all' },
  OWNER: { id: 'owner', title: 'Facilitator view' },
  MEMBER: { id: 'member', title: 'Member view' },
};

export interface KudosConfig {
  filterPersonId?: string | null;
}

export interface KudosPermissions {
  owner: string;
  admins: string[];
  members: string[];
  viewers: string[];
}

export interface Kudo {
  id: string;
  title?: string;
  description?: string;
  type: KudoCategory;
  from?: TeamMember;
  to?: TeamMember;
  anonymous?: boolean;
  email?: boolean;
  date?: number;
  team?: string;
  comments?: Comments;
  likes?: Likes;
  loves?: Loves;
}
// export type Kudos = Kudo[];
export type Kudos = { [kudoId: string]: Kudo };

export interface KudosWall {
  id: string;
  title: string;
  description: string;
  background: string;
  type: KudosWallType;
  status: KudosWallStatus;
  kudoCategories?: KudoCategories;
  kudos: Kudos;
  people: TeamMembers;
  config: KudosConfig;
  owner: string;
  createdOn?: number;
  updatedOn?: number | null;
  permissions?: KudosPermissions;
}
export type KudosWalls = KudosWall[];
