
import { Vue, Component, Prop } from 'vue-property-decorator';
import { formatDate } from '@/utils';
import { KudosWalls } from '../models';
import { kudosService } from '../services';
import KudosWallActions from './KudosWallActions.vue';

@Component({ components: { KudosWallActions } })
export default class KudosWallsList extends Vue {
  @Prop({ type: Array as () => KudosWalls, required: true }) readonly kudosWalls!: KudosWalls;
  @Prop({ type: Object, default: () => {} }) readonly config!: Object;

  formatDate = formatDate;
  kudosService = kudosService;

  get viewModeGrid(): boolean {
    return this.$store.getters['user/viewModeGrid'];
  }
}
