
import { Vue, Component, ModelSync, Prop } from 'vue-property-decorator';
import { KudosWall, Kudo, User } from '../../models';
import Person from '@/components/Person.vue';
import EmojiPicker from '@/components/EmojiPicker.vue';
import { kudosService } from '../../services';

@Component({ components: { Person, EmojiPicker } })
export default class KudoEditDialog extends Vue {
  @ModelSync('value', 'show', { default: false }) show!: Boolean;

  @Prop({ required: true }) readonly kudosWall!: KudosWall;
  @Prop({ required: true }) readonly kudo!: Kudo;
  @Prop({ default: false }) readonly save!: boolean;

  valid: boolean = false;

  get user(): User {
    return this.$store.getters['user/user'];
  }

  get people() {
    return Object.values(this.kudosWall.people);
  }

  get isNewKudo() {
    return !this.kudo.date;
  }

  selectEmoji(emoji: string) {
    console.log('emoji', emoji);
    if (emoji) this.kudo.description += emoji;
  }

  saveKudo(kudo: Kudo) {
    this.$emit('save', kudo);
  }

  onShareChange(anonymous: boolean) {
    // if (!anonymous) {
    //   this.kudo.from = this.user;
    // } else {
    //   this.kudo.from = undefined;
    // }
  }

  async generateMessage() {
    // const prompt = `Generate a simple, short (250 characters max) appreciation message (kudo) for team member "${
    //   this.kudo.to?.username || this.kudo.to?.displayName
    // }" of type "${this.kudo.type.title}"`;
    const prompt = `Generate a simple, short (250 characters max) appreciation message (kudo) of type "${this.kudo.type.title}". Additional context: "${this.kudo.description}"`;
    const result = await kudosService.genaiChat(prompt);
    console.log('genaiChat result', result);
    const text = result.data;
    this.kudo.description = text;
  }
}
